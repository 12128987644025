import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { rhythm, scale } from '../utils/typography'
import NewsletterForm from '../components/NewsletterForm'
import BookPromo from '../components/BookPromo'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const rootUrl = 'https://alexkondov.com'
    const socialImage =
      this.props.data.cover.childImageSharp.fluid.src

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta
            property="og:title"
            content={`${post.frontmatter.title} | ${siteTitle}`}
          />
          <meta property="og:description" content={post.excerpt} />
          <meta
            property="og:image"
            content={`${rootUrl}${socialImage}`}
          />
          <meta
            property="og:url"
            content={`${rootUrl}${post.fields.slug}`}
          />
          <meta
            name="twitter:card"
            content="summary_large_image"
          ></meta>
          <meta property="og:type" content="article" />
          <meta property="og:locale" content="en_US" />
          <link
            rel="canonical"
            href={`${rootUrl}${post.fields.slug}`}
          />
          <script
            async
            src="https://platform.twitter.com/widgets.js"
            charset="utf-8"
          ></script>
        </Helmet>
        <SEO
          title={post.frontmatter.title}
          description={post.excerpt}
        />
        {post.frontmatter.articleImage ? (
          <img
            className="post-image"
            src={post.frontmatter.articleImage}
            style={{
              objectFit: 'cover'
            }}
          />
        ) : null}
        <h1 className="post-title" style={{ marginTop: 0 }}>
          {post.frontmatter.title}
        </h1>
        <p
          className="post-subtitle"
          style={{
            ...scale(-1 / 5),
            display: `block`,
            marginBottom: rhythm(1),
            marginTop: rhythm(-1),
          }}
        >
          {post.timeToRead} minute read
        </p>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <>
          <NewsletterForm />
          <BookPromo
            image={
              this.props.data.bookCover.childImageSharp.fluid.src
            }
          />
        </>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    bookCover: file(
      absolutePath: { regex: "/tao-of-react-new-cover.png/" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    avatar: file(absolutePath: { regex: "/profile-pic-2.jpg/" }) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    cover: file(absolutePath: { regex: "/cover.png/" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      timeToRead
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        articleImage
        tags
      }
    }
    previous: markdownRemark(
      fields: { slug: { eq: $previousPostId } }
    ) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(fields: { slug: { eq: $nextPostId } }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
