import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import icon from '../../static/favicon.ico'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet
          link={[
            {
              rel: 'shortcut icon',
              type: 'image/png',
              href: `${icon}`,
            },
          ]}
        >
          <meta
            property="og:image"
            content={`https://alexkondov.com${this.props.data.cover.childImageSharp.fluid.src}`}
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta
            property="og:title"
            content="Alex Kondov's Personal Blog"
          />
          <meta
            property="og:description"
            content="Thoughts on programming, writing and the things in between."
          />
          <meta property="og:url" content="https://alexkondov.com" />
          <meta
            name="twitter:card"
            content="summary_large_image"
          ></meta>
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="en_US" />
          <title>Alex Kondov - Short Stories</title>
        </Helmet>
        <h2
          className="section-title"
          style={{
            fontFamily: `Merriweather, serif`,
          }}
        >
          Fiction
        </h2>
        <p style={{ marginBottom: 40, textAlign: 'center' }}>
          After dark I trade the keyboard for a pen and write fantasy
          short stories.
        </p>
        <p style={{ marginBottom: 40, textAlign: 'center' }}>
          The lines between fantasy and reality have been blurry to me
          ever since I was little. My imagination gave birth to
          countless heroes, villains and places that didn't belong to
          the world we know.
        </p>
        <p style={{ marginBottom: 40, textAlign: 'center' }}>
          Growing up in Eastern Europe, I wanted to read more stories
          that were inspired by our mythology and folklore, but they
          remained out of the mainstream.
        </p>
        <p style={{ marginBottom: 100, textAlign: 'center' }}>
          So I decided to write them myself.
        </p>

        <a href="https://brokenstatuesbook.com">
          <div className="book">
            <img
              style={{ borderRadius: 3 }}
              src={data.storiesBookCover.childImageSharp.fluid.src}
            />
            <div>
              <h2 style={{ marginTop: 10, fontWeight: 400 }}>
                Broken Statues
              </h2>
              <div className="divider"></div>
              <p>
                My debut collection of fantasy short stories inspired
                by Eastern European mythology.
              </p>
            </div>
          </div>
        </a>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    cover: file(absolutePath: { regex: "/cover.png/" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    storiesBookCover: file(absolutePath: { regex: "/cover3.png/" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
